import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Drawer, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { map } from 'lodash';
import { SidebarWidth } from 'themes/main';
import { useAuthFunctions } from 'contexts/AuthContext';
import { useHistory, useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import clientsLogo from '_assets/dashboard/clients-logo.svg';
import clientsLogoBold from '_assets/dashboard/clients-logo-bold.svg';
import dashboardLogo from '_assets/dashboard/dashboard-logo.svg';
import dashboardLogoBold from '_assets/dashboard/dashboard-logo-bold.svg';
import analyticsLogo from '_assets/dashboard/analytics.svg';
import providersLogo from '_assets/dashboard/presta-logo.svg';
import instagramLogo from '_assets/common/instagram-logo.svg';
import instagramLogoBold from '_assets/common/instagram-logo-bold.svg';
import tripsLogo from '_assets/dashboard/trips.svg';
import contentsLogo from '_assets/dashboard/analytics.svg'; // TODO
// import MaiaLogo from 'components/MaiaLogo';
import maiaLogo from "_assets/common/maia-logo.svg";
// import ProviderProfilePicture from 'components/ProviderProfilePicture';
import routes from 'config/routes';
import settingsLogo from '_assets/dashboard/settings-logo.svg';
import settingsLogoBold from '_assets/dashboard/settings-logo-bold.svg';
import useStyles from './styles';
import { useAdmin } from 'contexts/AdminContext';

const SideBar = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation();
    const { provider } = useAdmin();

    const { logout } = useAuthFunctions();

    const firstItems = [
        { id: 0, route: routes.home.root, icon: dashboardLogo, boldIcon: dashboardLogoBold, text: 'Tableau de bord' },
        { id: 1, route: routes.analytics.root, icon: analyticsLogo, boldIcon: analyticsLogo, text: 'Analytics' },
        { id: 2, route: routes.clients.root, icon: clientsLogo, boldIcon: clientsLogoBold, text: 'Clients' },
        { id: 3, route: routes.prestataires.root, icon: providersLogo, boldIcon: providersLogo, text: 'Prestataires' },
        { id: 4, route: routes.contents.root, icon: contentsLogo, boldIcon: contentsLogo, text: 'Activités/Evenements' },
        { id: 5, route: routes.bookings.root, icon: tripsLogo, boldIcon: tripsLogo, text: 'Voyages', travelAgencyAccess: true },
        {
            id: 6, route: routes.socialNetworks.root,
            icon: instagramLogo,
            boldIcon: instagramLogoBold,
            text: 'Réseaux Sociaux'
        },
        {
            id: 7, route: routes.payment.root,
            icon: settingsLogo,
            boldIcon: settingsLogoBold,
            text: 'Stripe'
        },
        { id: 8, route: routes.helpRequests.root, icon: settingsLogo, boldIcon: settingsLogoBold, text: 'Demandes d\'assistance' },
        { id: 9, route: routes.parameters.root, icon: settingsLogo, boldIcon: settingsLogoBold, text: 'Paramètres' },
    ].filter((item) => { return provider.is_travel_agency === true ? item.travelAgencyAccess === true : true });

    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        switch (location.pathname.split('/')[1]) {
            case 'analytics':
                setSelectedIndex(1);
                break;
            case 'clients':
                setSelectedIndex(2);
                break;
            case 'prestataires':
                setSelectedIndex(3);
                break;
            case 'contents':
                setSelectedIndex(4);
                break;
            case 'trips':
                setSelectedIndex(5);
                break;
            case 'social-networks':
                setSelectedIndex(6);
                break;
            case 'payment':
                setSelectedIndex(7);
                break;
            case 'help-requests':
                setSelectedIndex(8);
                break;
            case 'parameters':
                setSelectedIndex(9);
                break;
            default:
                setSelectedIndex(0);
                break;
        }
    }, [location]);

    const handleDisconnect = async () => {
        await logout();
        await history.push(routes.home.root);
    };

    const SidebarContent = (
        <Box className={classes.root}>
            <Box className={classes.header}>
                {/* <ProviderProfilePicture /> */}
                <Typography className={classes.welcomeText} variant="subtitle1">
                    Bonjour !
                </Typography>
            </Box>

            {[firstItems].map((items, index) => (
                <Box key={`box-${index}`} className={classes.listContainer}>
                    <List>
                        {map(items, ({ id, route, icon, boldIcon, text }) => (
                            <ListItem
                                button
                                className={classes.listItem}
                                key={id}
                                onClick={() => {
                                    history.push(route);
                                }}
                                selected={selectedIndex === id}
                                style={{
                                    ...(selectedIndex === id
                                        ? {
                                            color: 'white',
                                            backgroundColor: theme.palette.background.leftMenuSelected,
                                            border: `1px solid ${theme.palette.blue.light}`,
                                        }
                                        : {
                                            color: 'grey',
                                        }),
                                }}
                            >
                                <ListItemIcon>
                                    <i
                                        style={{
                                            WebkitMaskSize: 'cover',
                                            maskSize: 'cover',
                                            WebkitMaskImage: selectedIndex === id ? `url(${boldIcon})` : `url(${icon})`,
                                            maskImage: `url(${icon})`,
                                            backgroundColor: selectedIndex === id ? 'white' : theme.palette.common.black,
                                            width: 24,
                                            height: 24,
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primaryTypographyProps={{ style: selectedIndex === id ? { fontWeight: 500 } : { fontWeight: 300 } }}
                                >
                                    {text}
                                </ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            ))}

            <Box className={classes.logoutContainer}>
                <Button className={classes.logoutButton} onClick={handleDisconnect}>
                    Se déconnecter
                </Button>
                <img src={maiaLogo} alt="Maia logo" width="50%" />
            </Box>
        </Box>
    );

    return (
        <Drawer
            anchor="left"
            open={true}
            variant="persistent"
            PaperProps={{
                style: {
                    backgroundColor: theme.palette.background.leftMenuSelected,
                    width: SidebarWidth,
                },
            }}
        >
            {SidebarContent}
        </Drawer>
    );
};

export default SideBar;
